import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Session, useApi } from '../../context/ApiContext';

interface InstructorSessionsModalProps {
  instructorId?: number;
  onClose: () => void;
}

const InstructorSessionsModal: React.FC<InstructorSessionsModalProps> = ({ instructorId, onClose }) => {
  const { sessions, fetchSessions } = useApi();
  const [sessionsForInstructor, setSessionsForInstructor] = useState<Session[]>([]);
  const [editMode, setEditMode] = useState<number | null>(null); // Edit state by session ID
  const [selectedSessionId, setSelectedSessionId] = useState<number | null>(null); // For dropdown selection

  useEffect(() => {
    if (sessions && instructorId) {
      // Filter sessions based on instructor ID
      setSessionsForInstructor(
        sessions.filter((session) => session.instructor_attended === instructorId)
      );
    }
  }, [sessions, instructorId]);

  const handleEditSession = (sessionId: number) => {
    setEditMode(sessionId);
  };

  const handleSaveSession = async (sessionId: number, updatedSession: Partial<Session>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com/api/sessions/${sessionId}/`, updatedSession);
      setEditMode(null);
      // Update local state with the updated session
      setSessionsForInstructor((prevSessions) =>
        prevSessions.map((session) =>
          session.id === sessionId ? { ...session, ...updatedSession } : session
        )
      );
    } catch (error) {
      console.error('Error saving session:', error);
    }
  };

  const handleAssignSession = async () => {
    if (instructorId && selectedSessionId) {
      try {
        await axios.patch(`https://codeoceantech.pythonanywhere.com/api/sessions/${selectedSessionId}/`, {
          instructor_attended: instructorId,
        });
        // Update local state to reflect the assignment
        fetchSessions();
      } catch (error) {
        console.error('Error assigning session:', error);
      }
    }
  };

  const handleUnassignSession = async (sessionId: number) => {
    try {
      await axios.patch(`https://codeoceantech.pythonanywhere.com/api/sessions/${sessionId}/`, {
        instructor_attended: null, // Unassign the session
      });
      // Update local state to reflect the unassignment
      setSessionsForInstructor((prevSessions) =>
        prevSessions.filter((session) => session.id !== sessionId)
      );
      fetchSessions();
    } catch (error) {
      console.error('Error unassigning session:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-md w-2/4 max-h-full overflow-y-auto">
        <h2 className="text-2xl mb-4">Sessions for Instructor ID: {instructorId}</h2>
        
        <div className="mb-4">
          <label htmlFor="session-select" className="block text-lg mb-2">Assign New Session:</label>
          <select
            id="session-select"
            value={selectedSessionId || ''}
            onChange={(e) => setSelectedSessionId(Number(e.target.value))}
            className="border rounded p-2 w-full"
          >
            <option value="">Select a session</option>
            {sessions
              .filter((session) => session.instructor_attended !== instructorId && !session.instructor_attended) // Exclude already assigned sessions
              .map((session) => (
                <option key={session.id} value={session.id}>
                  {session.title}
                </option>
              ))}
          </select>
          <button
            onClick={handleAssignSession}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
            disabled={!selectedSessionId}
          >
            Assign
          </button>
        </div>
        
        <table className="table-auto w-full text-left mb-4">
          <thead>
            <tr>
              <th className="px-4 py-2">Session Title</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Meeting Link</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sessionsForInstructor.map((session) => (
              <tr key={session.id}>
                <td className="border px-4 py-2">
                  {editMode === session.id ? (
                    <input
                      type="text"
                      defaultValue={session.title}
                      onBlur={(e) => handleSaveSession(session.id, { title: e.target.value })}
                      className="p-1 border rounded"
                    />
                  ) : (
                    session.title
                  )}
                </td>
                <td className="border px-4 py-2">
                  {editMode === session.id ? (
                    <input
                      type="text"
                      defaultValue={session.description}
                      onBlur={(e) => handleSaveSession(session.id, { description: e.target.value })}
                      className="p-1 border rounded"
                    />
                  ) : (
                    session.description
                  )}
                </td>
                <td className="border px-4 py-2">
                  {editMode === session.id ? (
                    <input
                      type="text"
                      defaultValue={session.meeting_link}
                      onBlur={(e) => handleSaveSession(session.id, { meeting_link: e.target.value })}
                      className="p-1 border rounded"
                    />
                  ) : (
                    <a href={session.meeting_link} target="_blank" rel="noopener noreferrer">
                      {session.meeting_link}
                    </a>
                  )}
                </td>
                <td className="border px-4 py-2 flex space-x-2">
                  {editMode === session.id ? (
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded"
                      onClick={() => handleSaveSession(session.id, {})}
                    >
                      Save
                    </button>
                  ) : (
                    <>
                      {/* <button
                        className="bg-gray-50 text-white px-4 py-2 rounded"
                        onClick={() => handleEditSession(session.id)}
                      >
                        Edit
                      </button> */}
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={() => handleUnassignSession(session.id)}
                      >
                        Unassign
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded">
          Close
        </button>
      </div>
    </div>
  );
};

export default InstructorSessionsModal;
