import React from 'react';
import { FaUser, FaGoogle, FaFacebook, FaTwitter, FaLinkedin, FaFemale } from 'react-icons/fa';
import { useApi } from '../../../../context/ApiContext';

import CourseContainer from './CourseContainerBadge';

const ProgressGroup: React.FC = () => {
  const { parents, instructors, child, courses } = useApi(); // Access the data from the API context

  // Get the current date and time
  const now = new Date();

  // Filter courses that are currently running (start_time < now and end_time is null or > now)
  const get_running_courses = courses.filter(
    (course) => 
      new Date(course.start_time) <= now && 
      (!course.end_time || new Date(course.end_time) > now)
  );
// console.log('from progress',courses.filter((course)=>course.sessions.filter((session)=>session.attendance.length>0)));
//   Get the number of children who are absent (not enrolled in any running course)
const childrenAttendanceCount = courses
.filter((course) => {
  const courseStart = new Date(course.start_time);
  const courseEnd = course.end_time ? new Date(course.end_time) : null;

  // Ensure the course is currently running
  return courseStart <= now && (!courseEnd || courseEnd > now);
})
.flatMap((course) => 
  course.sessions.filter((session) => session.attendance.length > 0 && !session.is_ended)
)
.reduce((total, session) => total + session.attendance.length, 0); // Sum up the attendance count
const uniqueChildren = new Set<number>(); // Use a Set to store unique child IDs

courses
  .filter((course) => {
    const courseStart = new Date(course.start_time);
    const courseEnd = course.end_time ? new Date(course.end_time) : null;

    // Ensure the course is currently running
    return courseStart <= now && (!courseEnd || courseEnd > now);
  })
  .flatMap((course) => 
    course.sessions.filter((session) => session.attendance.length > 0 && !session.is_ended)
  )
  .forEach((session) => {
    session.attendance.forEach((child) => {
      if (typeof child === 'string') {
        uniqueChildren.add(child); // Add the child ID if it's a string
      } else {
        uniqueChildren.add(child.id); // Extract the ID if child is an object
      }
    });
  });

const uniqueChildrenCount = uniqueChildren.size; // The number of unique children

console.log(`Number of unique children in live sessions: ${uniqueChildrenCount}`);

  return (
    <div className="bg-white shadow rounded-lg p-4 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
        <div className='flex gap-2  w-full justify-between'>
          {/* Display live courses dynamically */}
          <div className="border-l-4 border-blue-500 pl-4 py-2 mb-4">
            <div className="text-gray-600 text-sm">Live Courses</div>
            <div className="text-xl font-semibold">{get_running_courses.length}</div>
          </div>

          {/* Display absent children dynamically */}
          <div className="border-l-4 border-green-500 pl-4 py-2">
            <div className="text-gray-600 text-sm">Children Alive</div>
            <div className="text-xl font-semibold">{childrenAttendanceCount}</div>
          </div>

          {/* Display absent children dynamically unique one */}
          <div className="border-l-4 border-green-500 pl-4 py-2">
            <div className="text-gray-600 text-sm">Children Alive unique</div>
            <div className="text-xl font-semibold">{uniqueChildrenCount}</div>
          </div>

          <hr className="my-4" />
        </div>
        <div>
          {/* Cards displaying running courses */}
          <div className="flex flex-wrap gap-4">
            {get_running_courses.map((course) => (
              <CourseContainer course={course} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressGroup;
