import React, { ReactNode } from 'react';

interface CardFooterProps {
  children: ReactNode;
}

const CardFooter: React.FC<CardFooterProps> = ({ children }) => {
  return <div className="px-4 py-2 bg-gray-100 rounded-b-lg">{children}</div>;
};

export default CardFooter;
