import React from 'react';

const Typography: React.FC = () => {
  return (
    <div>
      <h2>Typography</h2>
      {/* Typography page content */}
    </div>
  );
};

export default Typography;
