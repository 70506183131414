import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register chart components
ChartJS.register(ArcElement, Tooltip, Legend);

// Define props interface
interface DonationsChartProps {
  children: number;
  parents: number;
  instructors: number;
}

const DonationsChart: React.FC<DonationsChartProps> = ({ children, parents, instructors }) => {
  const data = {
    labels: ['Children', 'Parents', 'Instructors'],
    datasets: [
      {
        label: 'Users Distribution',
        data: [children, parents, instructors], // Example data
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 205, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 205, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <div className="rounded-lg max-h-80">
      {/* <h4 className="text-xl font-bold text-gray-700">Users Distribution</h4> */}
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonationsChart;
