import React, { useState } from "react";
import { Session } from "../../context/ApiContext";

interface SessionFormProps {
  onSubmit: (newSession: Session) => void;
  courseId: number; // Assuming courseId is passed as a prop
}

const SessionForm: React.FC<SessionFormProps> = ({ onSubmit, courseId }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    meeting_link: "",
    start_time: "",
    end_time: "",
    exact_start_date: "",
    exact_end_date: "",
    is_Started: false,
    is_ended: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target as HTMLInputElement | HTMLTextAreaElement;
    const newValue = type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Filter out fields with no data
    const newSession: Partial<Session> = {
      title: formData.title || undefined,
      description: formData.description || undefined,
      meeting_link: formData.meeting_link || undefined,
      start_time: formData.start_time || undefined,
      end_time: formData.end_time || undefined,
      exact_start_date: formData.exact_start_date || undefined,
      exact_end_date: formData.exact_end_date || undefined,
      is_Started: formData.is_Started,
      is_ended: formData.is_ended,
      course: courseId || undefined, // Ensure course ID is valid
      attendance: [],
      absent: [],
    };

    onSubmit(newSession as Session);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Form fields */}
      <div>
        <label className="block text-sm font-medium">Title</label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Description</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
          rows={3}
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Meeting Link</label>
        <input
          type="text"
          name="meeting_link"
          value={formData.meeting_link}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Start Time</label>
        <input
          type="datetime-local"
          name="start_time"
          value={formData.start_time}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium">End Time</label>
        <input
          type="datetime-local"
          name="end_time"
          value={formData.end_time}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Exact Start Date</label>
        <input
          type="date"
          name="exact_start_date"
          value={formData.exact_start_date}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Exact End Date</label>
        <input
          type="date"
          name="exact_end_date"
          value={formData.exact_end_date}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Session Started</label>
        <input
          type="checkbox"
          name="is_Started"
          checked={formData.is_Started}
          onChange={handleChange}
          className="mt-1"
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Session Ended</label>
        <input
          type="checkbox"
          name="is_ended"
          checked={formData.is_ended}
          onChange={handleChange}
          className="mt-1"
        />
      </div>
      <div className="mt-4">
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Add Session
        </button>
      </div>
    </form>
  );
};

export default SessionForm;
