import React, { ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

interface RequireAuthProps {
  children: ReactNode;
  requiredRole: string; // Role required to access this route
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, requiredRole }) => {
  const authContext = useContext(AuthContext);
console.log();
  if (!authContext) {
    return <Navigate to="/login" />;
  }

  const { user, role, userInfo } = authContext;

  // Check if user is authenticated and has the correct role
  if (!user || !userInfo || role !== 'manager' || userInfo.user_type !== 'manager') {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default RequireAuth;
