import React, { useContext } from 'react';
import { FaBars, FaTimes, FaHome, FaInfo, FaCogs, FaEnvelope, FaMale } from 'react-icons/fa'; // Import necessary icons
import { logo } from '../assets/images';
import { AuthContext } from '../context/AuthContext';

const navigation = [
  { name: 'Home', href: '/home', icon: <FaHome /> },
  { name: 'Instructors', href: '/instructors', icon: <FaMale /> },
  { name: 'Parents', href: '/parents', icon: <FaMale /> },
  // { name: 'Children', href: '/children', icon: <FaMale /> },
  { name: 'Courses', href: '/courses', icon: <FaCogs /> },
];

interface AppSidebarProps {
  isOpen: boolean;
  isUnfoldable: boolean;
  onSidebarToggle: () => void;
  onSidebarUnfoldableToggle: () => void;
}

const AppSidebar: React.FC<AppSidebarProps> = ({
  
  isOpen,
  isUnfoldable,
  onSidebarToggle,
  onSidebarUnfoldableToggle
}) => {
  const authContext = useContext(AuthContext);

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-800 text-white shadow-lg transition-all duration-300 ease-in-out ${
        isOpen ? '-translate-x-0' : '-translate-x-52'
      } ${isUnfoldable ? 'w-64' : 'w-20'} z-50`}
    >
      {authContext?.token && 
      <>
      
      <div className="flex items-center justify-between p-4 border-b border-gray-700">
        <div className={`flex items-center transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          <img src={logo} alt="Logo" className="h-8 w-auto" />
        </div>
        <button
          className="text-white text-2xl"
          onClick={onSidebarToggle}
        >
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
      <nav className="mt-4">
        <ul className="space-y-2">
          {navigation.map((item) => (
            <li key={item.name} className="flex items-center">
              <a
                href={item.href}
                className="flex items-center px-4 py-2 hover:bg-gray-700 rounded group"
              >
                <span className={`text-xl mr-2 ${isOpen ? 'block' : 'block'}`}>
                  {item.icon}
                </span>
                <span className={`text-base font-medium ${isOpen ? 'block' : 'hidden'}`}>
                  {item.name}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="absolute bottom-0 left-0 w-full border-t border-gray-700">
        <button
          className="w-full py-2 text-white bg-gray-900"
          onClick={onSidebarUnfoldableToggle}
        >
          {isUnfoldable ? 'codeocean' : 'Expand'}
        </button>
      </div>
      </>
      }
    </div>
  );
};

export default React.memo(AppSidebar);
