import React, { ReactNode, CSSProperties } from 'react';

interface CardProps {
  children: ReactNode;
  style?: CSSProperties;
}

const Card: React.FC<CardProps> = ({ children, style }) => {
  return (
    <div className="shadow-lg rounded-lg bg-white" style={style}>
      {children}
    </div>
  );
};

export default Card;
