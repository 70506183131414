// src/App.tsx

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ApiProvider } from './context/ApiContext'; // Import the new ApiProvider
import AppContent from './components/AppContent';
import DefaultLayout from './layout/DefaultLayout';
import './index.css';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApiProvider>
        <Router>
          <DefaultLayout>
            <AppContent />
          </DefaultLayout>
        </Router>
      </ApiProvider>
    </AuthProvider>
  );
};

export default App;
