import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { MdCheck } from 'react-icons/md';

interface DropdownProps {
  selectedValues: string[];  // Selected courses (titles)
  options: string[];         // Available course titles
  onChange: (values: string[]) => void;  // Handle course selection changes
}

const Dropdown: React.FC<DropdownProps> = ({ selectedValues, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionToggle = (value: string) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)  // Remove if already selected
      : [...selectedValues, value];                // Add if not selected

    onChange(updatedValues);  // Pass updated selection to the parent component
  };

  return (
    <div className="relative inline-block text-left w-full ">
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between w-full px-4 py-2 text-gray-800 bg-white border border-gray-300 rounded-md shadow-md transition duration-300 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {selectedValues.length > 0 ? selectedValues.join(', ') : 'Select options'} {/* Display selected course titles */}
        <FaCaretDown className="ml-2 h-5 w-5 text-gray-500 transition-transform duration-300" />
      </button>

      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-full origin-top-right bg-white border border-gray-300 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto transition-transform duration-300 ease-in-out "
          style={{ transform: isOpen ? 'scale(1)' : 'scale(0.95)', opacity: isOpen ? 1 : 0 }}
        >
          <div className="p-2">
            {options.map((option) => (
              <button
                key={option}
                onClick={() => handleOptionToggle(option)}
                className={`flex justify-between w-full px-4 py-2 mt-1 text-gray-700 transition-colors duration-200 ease-in-out rounded-md ${
                  selectedValues.includes(option)
                    ? 'bg-blue-100 text-blue-700 font-semibold'
                    : 'hover:bg-gray-100'
                }`}
              >
                {option}
                {selectedValues.includes(option) && (
                  <MdCheck className="text-blue-600" />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
