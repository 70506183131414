import React, { useState } from 'react';
import { AppFooter, AppHeader, AppSidebar } from '../components';

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar state
  const [isSidebarUnfoldable, setIsSidebarUnfoldable] = useState(true); // Sidebar unfoldable state

  const handleSidebarToggle = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const handleSidebarUnfoldableToggle = () => {
    setIsSidebarUnfoldable(prev => !prev);
  };

  return (
    <div className="flex h-screen">
      <AppSidebar
        isOpen={isSidebarOpen}
        isUnfoldable={isSidebarUnfoldable}
        onSidebarToggle={handleSidebarToggle}
        onSidebarUnfoldableToggle={handleSidebarUnfoldableToggle}
      />
      <div
        className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${
          isSidebarOpen ? 'ml-64' : 'ml-20'
        }`}
      >
        <AppHeader />
        <div className="flex-1 overflow-y-auto">
          {children}
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default DefaultLayout;
