import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Dropdown from "./Dropdown";
import { Course, useApi } from "../../../../context/ApiContext";

// Define types for Child and Request
interface Child {
  id: string;
  name: string;
  courses: string[];  // Course IDs
  completed: boolean;
}

interface Request {
  parentName: string;
  parentNumber: string;
  children: Child[];
}

const PendingRequestsTable: React.FC = () => {
  const { parents, child, courses, fetchChildren, fetchParents, fetchCourses } = useApi();
  const [requests, setRequests] = useState<Request[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Format requests with associated children
  useEffect(() => {
    if (parents && child) {
      try {
        const formattedRequests: Request[] = parents.map((parent) => {
          const parentChildren: Child[] = child
            .filter((ch) => ch.parent === parent.id)
            .map((ch) => ({
              id: String(ch.id),
              name: ch.name,
              courses: ch.courses.map(String),
              completed: ch.courses.length > 0,
            }));

          return {
            parentName: parent.user.username,
            parentNumber: parent.user.phone_number,
            children: parentChildren,
          };
        });
        setRequests(formattedRequests);
      } catch (err) {
        setError("Failed to process data.");
        console.error(err);
      }
    }
  }, [parents, child]);

  // Handle course changes for a specific child
  const handleCourseChange = (parentIndex: number, childIndex: number, selectedTitles: string[]) => {
    const updatedRequests = [...requests];
    const child = updatedRequests[parentIndex].children[childIndex];

    const selectedCourseIds = selectedTitles
  .map((title) => {
    const course = courses.find((course) => course.title === title);
    return course?.id ? course.id.toString() : undefined;
  })
  .filter(Boolean) as string[]; // Ensure only valid strings are included

    child.courses = selectedCourseIds;
    setRequests(updatedRequests);

    sendCourseUpdate(child.id, selectedCourseIds);
  };

  const sendCourseUpdate = (childId: string, courseIds: string[]) => {
    // API call to update the courses for the child
    const apiUrl = `https://codeoceantech.pythonanywhere.com/api/children/${childId}/update-courses/`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify({ courses: courseIds }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(`Server error: ${data.error}`);
        } else {
          fetchParents();
          fetchCourses();
          fetchChildren();
        }
      })
      .catch((err) => {
        setError("Error updating courses.");
        console.error(err);
      });
  };

  return (
    <div className="bg-white shadow rounded-lg p-4">
      {error && <div className="bg-red-200 text-red-700 p-3 mb-4 rounded">{error}</div>}
      <table className="w-full border-separate border-spacing-0 border border-gray-300">
        <thead>
          <tr className="bg-gray-200 border-b border-gray-300">
            <th className="p-3 text-left">Parent Name</th>
            <th className="p-3 text-left">Parent Number</th>
            <th className="p-3 text-left">Child</th>
            <th className="p-3 text-left">Courses</th>
            <th className="p-3 text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, parentIndex) =>
            request.children.map((child, childIndex) => {
              const selectedCourseTitles = child.courses.map(
                (courseId) => courses.find((course) => String(course.id) === courseId)?.title || ""
              );

              return (
                <tr key={`${parentIndex}-${childIndex}`} className="border-b border-gray-200">
                  <td className="p-3">{request.parentName}</td>
                  <td className="p-3">{request.parentNumber}</td>
                  <td className="p-3">{child.name}</td>
                  <td className="p-3">
                    <Dropdown
                      selectedValues={selectedCourseTitles}
                      options={courses.map((course) => course.title)}
                      onChange={(selectedTitles) => handleCourseChange(parentIndex, childIndex, selectedTitles)}
                    />
                  </td>
                  <td className="p-3 text-center">
                    {child.completed ? (
                      <span className="text-green-500 flex items-center">
                        <FaCheckCircle className="mr-1" /> Completed
                      </span>
                    ) : (
                      <span className="text-red-500 flex items-center">
                        <FaTimesCircle className="mr-1" /> Not Completed
                      </span>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PendingRequestsTable;
