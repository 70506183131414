// TrafficChart.tsx
import React, { useState, useEffect } from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import DonationsChart from './DonationsChart'; // Import the DonationsChart component
import { useApi } from '../../../../context/ApiContext';

const TrafficChart: React.FC = () => {
  const [time, setTime] = useState(new Date());
  const {parents,instructors,child} = useApi(); // Access the lengths from the API context

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-4">
      {/* <div className="flex justify-between items-center mb-4">
        <h4 className="text-xl font-bold text-gray-700">Traffic Overview</h4>
        <div className="hidden md:flex space-x-2">
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">Day</button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">Month</button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">Year</button>
          <button className="p-2 bg-gray-300 text-gray-600 rounded-full hover:bg-gray-400 transition">
            <FaCloudDownloadAlt    />
          </button>
        </div>
      </div> */}
      <div className="relative mb-4">
        <div className="h-100 sm:h-80 md:h-96 lg:h-108 xl:h-128  rounded-lg">
          <DonationsChart children={child.length} parents={parents.length} instructors={instructors.length} /> {/* Display the DonationsChart component here */}
        </div>
        <div className="absolute top-2 right-2  p-2 rounded-full shadow-md">
          <span className="text-sm font-semibold">{time.toLocaleTimeString()}</span>
        </div>
      </div>
    </div>
  );
};

export default TrafficChart;
