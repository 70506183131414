import React from 'react';
import { useNavigate } from 'react-router-dom';

const Page404: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-300">404</h1>
        <h2 className="text-2xl font-semibold mt-4">Oops! You{"'"}re lost.</h2>
        <p className="text-gray-600 mt-2">The page you are looking for was not found.</p>
        {/* <div className="mt-6">
          <input
            type="text"
            placeholder="What are you looking for?"
            className="px-4 py-2 border rounded-md shadow-sm"
          />
          <button
            onClick={() => navigate('/')}
            className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Search
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Page404;
