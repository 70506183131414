import React, { useState, useEffect } from 'react';
import { Parent, Child, useApi } from '../../context/ApiContext'; // Import the API context
import ParentCreationForm from '../../components/common/ParentCreationForm'; // Import the ParentCreationForm component
import ParentFormUpdate from '../../components/common/ParentFormUpdate'; // Import the ParentFormUpdate component
import Dropdown from '../base/home/components/Dropdown';

interface ParentFormUpdateProps {
  parent: {
    id: number;
    user?: number;
    username: string;
    password: string;
    email: string;
    phone_number: string;
    children: Child[];
  } | null;
}

interface ParentFormCreateProps {
  parent?: {
    username: string;
    password: string;
    email: string;
    phone_number: string;
    children: Child[];
  } | null;
}

const ParentManagement: React.FC = () => {
  const { parents, fetchParents, createParent, deleteParent, child } = useApi(); // Remove updateParent and parentsEnhanced
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedParent, setSelectedParent] = useState<ParentFormUpdateProps['parent']>(null);
  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);

  useEffect(() => {
    const fetchAndEnhanceParents = async () => {
      await fetchParents(); // Fetch parents data
      // Assuming fetchParents updates the `parents` directly in the API context
    };

    fetchAndEnhanceParents();
  }, []);

  // Prepare the parent data for updating
  const prepareUpdateData = (parentData: Parent, allChildren: Child[]): ParentFormUpdateProps['parent'] => {
    // Filter children to include only those that match the parent ID
    const filteredChildren = allChildren.filter(child => child.parent === parentData.id);

    // Create a new variable for the update payload
    const updatePayload: ParentFormUpdateProps['parent'] = {
      id: parentData.id,
      username: parentData.user.username,
      user: parentData.user.id,
      password: '', // Include an empty string for password; this should be updated by the form
      email: parentData.user.email,
      phone_number: parentData.user.phone_number,
      children: filteredChildren.map(child => ({
        id: child.id,
        parent: parentData.id,
        name: child.name,
        age: child.age,
        experience: child.experience,
        courses: [],
        clear_username: child.clear_username,
        clear_password: child.clear_password,
      })),
    };

    return updatePayload;
  };

  const handleAdd = () => {
    setIsAdding(true);
    setIsEditing(false);
  };

  const handleEdit = (parent: Parent) => {
    setIsEditing(true);
    setIsAdding(false);
    const parentData = prepareUpdateData(parent, child);
    if (parentData) {
      setSelectedParent(parentData);
      setSelectedChildren(parentData.children.map(child => child.name));
    }
  };

  const handleSubmitCreate = (formData: any) => {
    if (formData) {
      createParent(formData);
    }
    setIsAdding(false);
  };

  const handleDelete = (id: number) => {
    deleteParent(id);
  };

  const handleSubmit = () => {
    setIsEditing(false);
    fetchParents();
  };

  const handleChildrenChange = (selectedValues: string[]) => {
    setSelectedChildren(selectedValues);
    if (selectedParent) {
      const updatedParent = {
        ...selectedParent,
        children: selectedParent.children.filter(child => selectedValues.includes(child.name))
      };
      setSelectedParent(updatedParent);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Parents</h1>

      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Parent List</h2>
        <table className="min-w-full bg-white shadow-md rounded-md">
          <thead>
            <tr>
              <th className="border-b p-2 text-left">Name</th>
              <th className="border-b p-2 text-left">Email</th>
              <th className="border-b p-2 text-left">Phone</th>
              <th className="border-b p-2 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {parents.map((parent, idx) => (
              <tr key={idx}>
                <td className="border-b p-2">{parent.user.username}</td>
                <td className="border-b p-2">{parent.user.email}</td>
                <td className="border-b p-2">{parent.user.phone_number}</td>
                <td className="border-b p-2 text-right">
                  <button
                    className="bg-yellow-500 text-white p-2 rounded-md hover:bg-yellow-600 mr-2"
                    onClick={() => handleEdit(parent)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600"
                    onClick={() => handleDelete(parent.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-6">
        {!isAdding && !isEditing && (
          <button
            className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
            onClick={handleAdd}
          >
            Add New Parent
          </button>
        )}

        {isAdding && !isEditing && (
          <ParentCreationForm parent={selectedParent} onSubmit={handleSubmitCreate} />
        )}

        {isEditing && !isAdding && selectedParent && (
          <>
            <ParentFormUpdate parent={selectedParent} onSubmit={handleSubmit} />
            <Dropdown
              selectedValues={selectedChildren}
              options={child.map(c => c.name)} // Assuming `name` is unique
              onChange={handleChildrenChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ParentManagement;
