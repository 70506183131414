import React from 'react';
import Card from '../../cards/Cards';
import CardHeader from '../../cards/CardHeader';
import CardBody from '../../cards/CardBody';
import CardFooter from '../../cards/CardFooter';
import { Course } from '../../../../context/ApiContext';


interface CourseProps {
  course: Course
}

const CourseContainer: React.FC<CourseProps> = ({ course }) => {
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Card style={{ width: 'fit-content' }}>
      <CardHeader color="bg-green-500">
        <div color="primary">{course.title}</div>
      </CardHeader>
      <CardBody>
        <p>
          <div className="bg-blue-400 px-4 rounded-2xl  py-1  secondary my-1">Start Date {formatDate(course.start_time)}</div> 
        </p>
        <p>
          {course.end_time ? (
            <>
              <div className="bg-yellow-100 px-4 rounded-2xl  py-1  secondary my-1">End Date {formatDate(course.end_time)}</div> 
            </>
          ) : (
            <div className="bg-yellow-100 px-4 rounded-2xl  py-1  secondary">Ongoing</div>
          )}
        </p>
      </CardBody>
      <CardFooter>
        <p>Course Sessions: {course.sessions.length}</p>
      </CardFooter>
    </Card>
  );
};

export default CourseContainer;
