import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Dashboard: React.FC = () => {
  const authContext = useContext(AuthContext); // Handle possible null values

  return (
    <div className="dashboard-page">
      <h2>Dashboard</h2>
      {authContext ? (
        <>
          <p>Welcome, {authContext.user}</p>
          <p>Role: {authContext.role}</p>
          <p>Parent Name: {authContext.parent?.user}</p>
          <p>Email: {authContext.userInfo?.email}</p>
          <button onClick={authContext.logout}>Logout</button>
        </>
      ) : (
        <p>Loading...</p> // Show some loading state or error handling
      )}
    </div>
  );
};

export default Dashboard;
