import React, { useState } from 'react';
import { useApi } from '../../../context/ApiContext';
import InstructorSessionsModal from '../../../components/common/InstructorSessionsModal';
import axios from 'axios';

// Define the interfaces as provided
export interface User {
  id: number;
  username: string;
  phone_number: string;
  user_type: string;
  email: string;
  password: string;
}

export interface Instructor {
  id: number;
  user: User;
  // Other fields as needed
}

const InstructorsTable: React.FC = () => {
  const { instructors, fetchInstructors } = useApi();
  const [selectedInstructor, setSelectedInstructor] = useState<number | null>(null);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [newInstructor, setNewInstructor] = useState<Instructor>({
    id: 0,
    user: {
      id: 0,
      username: '',
      phone_number: '',
      user_type: 'instructor', // Fixed user_type
      email: '',
      password: '',
    },
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const handleOpenModal = (instructorId: number) => {
    setSelectedInstructor(instructorId);
  };

  const validateField = async (field: string, value: string) => {
    let url = '';
    if (field === 'username') url = `https://codeoceantech.pythonanywhere.com/api/accounts/check-username/?username=${value}`;
    if (field === 'email') url = `https://codeoceantech.pythonanywhere.com/api/accounts/check-email/?email=${value}`;
    if (field === 'phone_number') url = `https://codeoceantech.pythonanywhere.com/api/accounts/check-phone-number/?phone_number=${value}`;

    try {
      const response = await axios.get(url);
      if (response.data.exists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} already exists.`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: '',
        }));
      }
    } catch (error) {
      console.error('Validation error:', error);
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewInstructor((prevInstructor) => ({
      ...prevInstructor,
      user: {
        ...prevInstructor.user,
        [name]: value,
      },
    }));

    // Validate the field
    await validateField(name, value);
  };

  const handleAddInstructor = async () => {
    setLoading(true);
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com/api/create/instructor/', newInstructor);
      setNewInstructor({
        id: 0,
        user: {
          id: 0,
          username: '',
          phone_number: '',
          user_type: 'instructor',
          email: '',
          password: '',
        },
      });
      setShowAddForm(false);
      fetchInstructors(); // Refresh instructor list
    } catch (error) {
      console.error('Error adding instructor:', error);
    }
    setLoading(false);
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-semibold mb-6 text-gray-800">Instructors</h1>

      {/* Button to show add instructor form */}
      <button
        onClick={() => setShowAddForm(true)}
        className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition-colors mb-4"
      >
        Add New Instructor
      </button>

      {/* New Instructor Form */}
      {showAddForm && (
        <div className="bg-white p-6 rounded-lg shadow-md mb-6 max-w-lg mx-auto">
          <h2 className="text-xl mb-4 font-semibold">Add New Instructor</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              name="username"
              value={newInstructor.user.username}
              onChange={handleChange}
              className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
                errors.username ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="Enter username"
            />
            {errors.username && <p className="mt-1 text-sm text-red-500">{errors.username}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              value={newInstructor.user.phone_number}
              onChange={handleChange}
              className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
                errors.phone_number ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="Enter phone number"
            />
            {errors.phone_number && <p className="mt-1 text-sm text-red-500">{errors.phone_number}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">User Type</label>
            <input
              type="text"
              name="user_type"
              value={newInstructor.user.user_type}
              readOnly
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-100"
              placeholder="Instructor"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={newInstructor.user.email}
              onChange={handleChange}
              className={`mt-1 block w-full border-gray-300 rounded-md shadow-sm ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="Enter email"
            />
            {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              name="password"
              value={newInstructor.user.password}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              placeholder="Enter password"
            />
          </div>
          <div className="flex gap-4">
            <button
              onClick={handleAddInstructor}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition-colors"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Instructor'}
            </button>
            <button
              onClick={() => setShowAddForm(false)}
              className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <div className="shadow-lg rounded-lg overflow-hidden">
        <table className="min-w-full bg-white">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider w-2">ID</th>
              <th className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider">Phone</th>
              <th className="px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {instructors.map((instructor, index) => (
              <tr
                key={instructor.id}
                className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-blue-50 transition-colors duration-200`}
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{instructor.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{instructor.user.username}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{instructor.user.phone_number}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleOpenModal(instructor.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition-colors"
                  >
                    View Sessions
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedInstructor && (
        <InstructorSessionsModal
          instructorId={selectedInstructor}
          onClose={() => setSelectedInstructor(null)}
        />
      )}
    </div>
  );
};

export default InstructorsTable;
