import React from 'react';
import TrafficChart from './components/TrafficChart';
import ProgressOverview from './components/ProgressOverview';
import ProgressGroup from './components/ProgressGroup';
import UserTable from './components/UserTable';

const Home: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <TrafficChart />
      <ProgressOverview />
      <ProgressGroup />
      <UserTable />
    </div>
  );
};

export default Home;
