import React, { ReactNode } from 'react';

interface CardHeaderProps {
  children: ReactNode;
  color?: string;
}

const CardHeader: React.FC<CardHeaderProps> = ({ children, color = 'bg-gray-200' }) => {
  return (
    <div className={`px-4 py-2 rounded-t-lg text-white ${color}`}>
      {children}
    </div>
  );
};

export default CardHeader;
