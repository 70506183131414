import React, { useContext, useState } from 'react';
import { Child } from '../../context/ApiContext';
import { AuthContext } from '../../context/AuthContext';

interface ParentFormUpdateProps {
  parent: {
    id:number;
    user?:number
    username: string;
    password: string;
    email: string;
    phone_number: string;
    children: Child[];
  } | null;
  onSubmit: () => void;
 
}

const ParentFormUpdate: React.FC<ParentFormUpdateProps> = ({ parent,onSubmit  }) => {
  const [formData, setFormData] = useState({
    username: parent?.username || '',
    password: '',
    email: parent?.email || '',
    user: parent?.user || '',
    phone_number: parent?.phone_number || '',
    children: parent?.children || [{ name: '', age: 0, experience: '' }], // Use basic structure for new children
  });
  const authContext = useContext(AuthContext); // Handle possible null values

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
    const { name, value } = e.target;

    if (index !== undefined) {
      const updatedChildren = formData.children.map((child, i) =>
        i === index ? { ...child, [name]: value } : child
      );
      setFormData({ ...formData, children: updatedChildren });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddChild = () => {
    setFormData({
      ...formData,
      children: [...formData.children, { name: '', age: 0, experience: '' }], // Add a new child with empty values
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  
    const updateUrl = `https://codeoceantech.pythonanywhere.com/api/parent/${parent?.id}/update/`;  // Assuming parent has an `id` field
  
    fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authContext?.token?.access}`, // Include JWT token here if needed
      },
      body: JSON.stringify(formData),  // Send the form data to the backend
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Parent updated successfully:', data);
        onSubmit()
        // Handle success (e.g., show a success message or navigate to another page)
      })
      .catch((error) => {
        console.error('Error updating parent:', error);
        // Handle error (e.g., show an error message)
      });
  };
  

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Form fields for Parent */}
      <div>
        <label className="block text-sm font-medium">Username</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium">Phone Number</label>
        <input
          type="text"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border rounded-md"
          required
        />
      </div>

      {/* Children Fields */}
      <div>
        <h3 className="text-lg font-semibold">Children</h3>
        {formData.children.map((child, index) => (
          <div key={index} className="mb-4">
            <div>
              <label className="block text-sm font-medium">Child Name</label>
              <input
                type="text"
                name="name"
                value={child.name}
                onChange={(e) => handleChange(e, index)}
                className="mt-1 block w-full p-2 border rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Age</label>
              <input
                type="number"
                name="age"
                value={child.age}
                onChange={(e) => handleChange(e, index)}
                className="mt-1 block w-full p-2 border rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium">Experience</label>
              <textarea
                name="experience"
                value={child.experience}
                onChange={(e) => handleChange(e, index)}
                className="mt-1 block w-full p-2 border rounded-md"
                rows={3}
              />
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddChild}
          className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Add Child
        </button>
      </div>
      
      {/* Submit Button */}
      <button
        type="submit"
        className="w-full bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
      >
        {parent ? 'Update Parent' : 'Add Parent'}
      </button>
    </form>
  );
};

export default ParentFormUpdate;
