import React, { useContext, useState } from "react";
import CourseForm from "../../components/common/CourseForm";
import { useApi } from "../../context/ApiContext";
import { Course } from "../../context/ApiContext";

function ParentComponentCourses() {
    const { parents, child, courses,createCourse,updateCourse  } = useApi(); // Fetch data from the API context
    const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);  // Type-safe selectedCourse
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const handleAdd = () => {
    setIsAdding(true);
    if (selectedCourse) {
        
        createCourse(selectedCourse)
    }
    // setSelectedCourse(null); // Reset the form for adding a new course
  };

  const handleEdit = (course: Course) => {
    setIsEditing(true);
    setSelectedCourse(course);
  };

  const handleSubmit = (courseData: Course) => {

    if (isEditing) {
    updateCourse(courseData.id,courseData)
    //   setCourses((prevCourses) =>
    //     prevCourses.map((c) =>
    //       c.title === selectedCourse?.title ? courseData : c
    //     )
    //   );
      setIsEditing(false);
    } else {
    //   setCourses((prevCourses) => [...prevCourses, courseData]);
    createCourse(courseData)
      setIsAdding(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Courses</h1>

      {/* List of courses */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Current Courses</h2>
        <table className="min-w-full bg-white shadow-md rounded-md">
          <thead>
            <tr>
              <th className="border-b p-2 text-left">Title</th>
              <th className="border-b p-2 text-left">Start Time</th>
              <th className="border-b p-2 text-left">End Time</th>
              <th className="border-b p-2 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course, idx) => (
              <tr key={idx}>
                <td className="border-b p-2">{course.title}</td>
                <td className="border-b p-2">{course.start_time}</td>
                <td className="border-b p-2">{course.end_time}</td>
                <td className="border-b p-2 text-right">
                  <button
                    className="bg-yellow-500 text-white p-2 rounded-md hover:bg-yellow-600 mr-2"
                    onClick={() => handleEdit(course)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add or Edit course form */}
      <div className="mb-6">
        {!isAdding && !isEditing && (
          <button
            className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
            onClick={handleAdd}
          >
            Add New Course
          </button>
        )}

        {(isAdding || isEditing) && (
          <CourseForm course={selectedCourse} onSubmit={handleSubmit} />
        )}
      </div>
    </div>
  );
}

export default ParentComponentCourses;
